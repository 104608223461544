* {
    margin:             0px;
    padding:            0px;
    box-sizing:         border-box;
    font-family:        sans-serif;
    white-space:        normal;
}

*:focus {
    outline:            none !important;
}

html, body {
    height:             100%;
}

h1 {
    font-size:          64px;
    line-height:        64px;
    font-family:        'Anton', sans-serif;
}

h2 {
    font-size:          24px;
    line-height:        30px;
    font-family:        'Anton', sans-serif;
    color:              white;
}

h3 {
    font-size:          32px;
    font-family:        'Anton', sans-serif;
    color:              red;
}

h4 {
    font-size:          24px;
    font-family:        'Anton', sans-serif;
    color:              red;
    margin:             10px 0px;
}

p {
    font-size:          16px;
    font-family:        sans-serif;
}

a {
    color:              #0bb;
    font-weight:        bold;
}

a:visited {
    color:              #aaa;
}

footer {
    font-size:          12px;
    color:              white;
}

.center {
    margin:             0;
    position:           absolute;
    top:                50%;
    left:               50%;
    transform:          translate(-50%, -50%);
}

.center-horizontal {
    margin:             0;
    position:           absolute;
    left:               50%;
    transform:          translateX(-50%);
}

.flex-center {
    display:            flex;
    justify-content:    center;
    align-items:        center;
    width:              100%;
    max-height:         100%;
}

.modal {
    position:           fixed;
    z-index:            1;
    left:               0;
    top:                0;
    width:              100%;
    height:             100%;
    overflow:           auto;
    background-color:   rgba(0,0,0,0.4);
}

.modal-content {
    background-color:   #fefefe;
    margin:             5% auto;
    padding:            20px;
    border:             1px solid #888;
    width:              80%;
    box-shadow:         0px 5px 35px 5px rgba(0, 0, 0, 0.25);
}

.modal-content > * {
    overflow:           visible;
    white-space:        normal;
}

.modal-content > p {
    margin-bottom:      10px;
}

/*================================================================*/
/* page */
/*================================================================*/
.page {
    container-name:     page;
    container-type:     size;
    position:           absolute;
    display:            flex;
    flex-direction:     column;
    width:              100%;
    height:             100%;
    overflow:           auto;
}

@container page (max-width: 1024px) {
    h1 {
        font-size:      52px;
        line-height:    52px;
    }

    h2 {
        font-size:      18px;
        line-height:    24px;
    }

    p {
        font-size:      14px;
    }
}

/*================================================================*/
/* header */
/*================================================================*/
.header {
    
    width:              100%;
    min-height:         96px;
    background-color:   #f00;
    flex-shrink:        0;
}

.title-group {
    padding:            0px 16px;
}

.sub-title {
    text-wrap:          wrap;
}

/*================================================================*/
/* slideshow */
/*================================================================*/

.slideshow {
    display:            flex;
    width:              100%;
    flex:               10;
    min-height:         200px;
    max-height:         640px;
}

.slideshow-left {
    background-color:   #00ffff;
    width:              48px;
}

.slideshow-center {
    display:            flex;
    justify-content:    center;
    align-items:        center;
    background-color:   #ffff77;
    flex:               1;
    overflow:           hidden;
}

.slideshow-right {
    background-color:   #00ff00;
    width:              48px;
}

.nav-button {
    width:              32px;
    height:             100px;
    background-color:   #ffff00;
    border:             0;
}

.nav-button:hover {
    background-color:   #ffffaa;
}

.nav-button:active {
    background-color:   #bbbbbb;
}

.aspect-outer {
    display:            flex;
    justify-content:    center;
    align-items:        center;
    height:             100%;
    max-width:          100%;
    aspect-ratio:       1024/512;
    overflow:           visible;
}

.slide-frame {
    display:            flex;
    justify-content:    center;
    align-items:        center;
    width:              100%;
    max-width:          1024px;
    min-height:         0px;
    background-color:   #fff;
    aspect-ratio:       1024/512;
    box-shadow:         0px 5px 35px 5px rgba(0, 0, 0, 0.25);
}

.slide-image {
    width:              96.875%;
    object-fit:         contain;
    aspect-ratio:       992/480;
}

/*================================================================*/
/* form */
/*================================================================*/
.form {
    display:            flex;
    flex-direction:     column;

    width:              100%;
    min-height:         192px;
    padding:            5px 0px;
    background-color:   #000;
    flex:               1;
    flex-shrink:        0;
}

.form-blurb {
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        center;
    gap:                8px;
    width:              100%;
    height:             100%;
}

.form-faq {
    color:              red;
    padding:            0px;
    margin:             0px;
    cursor:             pointer;
}

.form-instructions {
    font-size:          12px;
    line-height:        12px;
}

.form-blurb p {
    color:              #ffffff;
    line-height:        1.25em;
    padding:            5px 16px 5px 16px;
    max-width:          820px;
    text-wrap:          wrap;
    flex-shrink:        0;
}

@container page (max-width: 1024px) {
    .form-blurb p  {
        max-width:      640px;
    }
}

.message {
    position:           absolute;
    left:               50%;
    transform:          translateX(-50%);
    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;
}

/*================================================================*/
/* form input */
/*================================================================*/

.form-input {
    display:            flex;
    flex-direction:     column;
    gap:                3px;
    overflow:           visible;
}

.form-input-inner {
    display:            flex;
    height:             36px;
    overflow:           visible;
}

.form-input input {
    height:             100%; 
    padding:            0px 10px 0px 10px;
    border:             0;
    border-radius:      0;
}

.form-input input[type=text] {
    width:              256px;
    font-family:        'Courier', fixed-width;
    background-color:   white;
}

.form-input input[type=text]:focus {
    background-color:   #ffff77;
    border:             0;
}

.form-input input[type=submit] {
    width:              61px;
    background-color:   #ddd;
}

.form-input input[type=submit]:hover {
    background-color:   #7b7b7b;
}

.form-input input[type=submit]:active {
    background-color:   #7b7b7b;
    transform:          translateY(3px);
}

.form-input label {
    font-size:          14px;
    color:              white;
}

/*================================================================*/
/* footer */
/*================================================================*/

.action-area {
    display:            flex;
    flex-direction:     column;
    flex-grow:          1;
    flex-shrink:        0;
    justify-content:    space-between;
    background-color:   #000;
}

.footer {
    width:              100%;
    min-height:         24px;
    background-color:   #000;
}
